import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import { Layout } from '../../../../components/layout'
import { useStories } from '../../../../hooks/useStories'
import { useTags } from '../../../../hooks/useTags'
import { Stories } from '../../../../components/stories'
import { Article } from '../../../../components/article'
import { Story as StoryType } from '../../../../types/story'
import { Tag as TagType } from '../../../../types/tag'
import { SEO } from '../../../../components/seo'

const StoryPage = ({ params }: any) => {
	const { stories } = useStories()
	const [story, setStory] = useState<StoryType | null>(null)
	const { tags } = useTags()
	const [tag, setTag] = useState<TagType | null>(null)

	useEffect(() => {
		const story = stories.find(story => story.title === params._title)
		if (story) {
			setStory(story)
		}
	}, [params, stories])

	useEffect(() => {
		const tag = tags.find(tag => tag.title === params._tag)
		if (tag) {
			setTag(tag)
		}
	}, [params, tags])

	return (
		<Layout>
			<SEO
				title={`${params._title} > ${params._tag} | RUBIA ルビア 渋谷メキシカン【朝食・ランチ・バー】`}
				description={''}
				keywords={['メキシコ料理', '渋谷' , 'ファインメキシカン', 'ルビア', 'sarasa']}
			/>
			<h1 className="h1">{params._title} {`>`} {params._tag} | RUBIA ルビア / DJ sarasa</h1>
			<div className="story">
				{story && tag && (
					<>
						<Article type="story" article={story} tag={tag} />
						<Stories />
					</>
				)}
			</div>
		</Layout>
	)
}

export default StoryPage
